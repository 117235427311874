import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../utils/baseQueryWithAuth";

const authUrl = "https://auth-service.sangai.polarisgrids.com";
const mdmsUrl = process.env.REACT_APP_MDMS_URL;

export const cognitoUserAccessPanelApi = createApi({
  reducerPath: "cognitoUserAccessPanelApi",
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `${authUrl}/auth/users/forgot-password`,
        method: "POST",
        body: { email }
      })
    }),
    verifyMFA: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/verify-mfa`,
        method: "POST",
        body: data
      })
    }),
    forceChangePassword: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/force-change-password`,
        method: "PATCH",
        body: data
      })
    }),
    authenticateUser: builder.query({
      query: () => ({
        url: `${authUrl}/auth/users/authenticate`
      })
    }),
    getUsers: builder.query({
      query: () => ({
        url: `${authUrl}/auth/users`
      }),
      providesTags: ["getUsers"]
    }),
    getUserInfo: builder.query({
      query: (sub) => ({
        url: `${authUrl}/auth/users/${sub}`
      })
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users`,
        method: "POST",
        body: {
          ...data
        }
      })
    }),
    resendUserInvitation: builder.query({
      query: (params) => ({
        url: `${authUrl}/auth/users/resend-user-invitation`,
        method: "GET",
        params
      })
    }),
    getRoles: builder.query({
      query: () => ({
        url: `${authUrl}/auth/roles`,
        method: "GET"
      })
    }),
    updateUserRole: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/roles/update-user-role`,
        method: "POST",
        body: {
          ...data
        }
      })
    }),
    createRole: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/roles`,
        method: "POST",
        body: {
          ...data
        }
      })
    }),
    updateRole: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/roles`,
        method: "PUT",
        body: {
          ...data
        }
      })
    }),
    deleteRole: builder.mutation({
      query: (name) => ({
        url: `${authUrl}/auth/roles`,
        method: "DELETE",
        params: { name }
      })
    }),
    getPermissions: builder.query({
      query: (params) => ({
        url: `${authUrl}/auth/permissions`,
        method: "GET",
        params: params
      })
    }),
    getModules: builder.query({
      query: () => ({
        url: `${authUrl}/auth/permissions/modules`,
        method: "GET"
      })
    }),
    createPermission: builder.mutation({
      query: (newPermission) => ({
        url: `${authUrl}/auth/permissions`,
        method: "POST",
        body: newPermission
      })
    }),
    updatePermission: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/permissions`,
        method: "PUT",
        body: {
          ...data
        }
      })
    }),
    deletePermission: builder.mutation({
      query: (name) => ({
        url: `${authUrl}/auth/permissions`,
        method: "DELETE",
        params: { name }
      })
    }),
    confirmMFASetup: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/confirm-mfa`,
        method: "POST",
        body: data
      })
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/change-password`,
        method: "PATCH",
        body: data
      })
    }),
    updateUserDetails: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["getUsers"]
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/forgot-password`,
        method: "POST",
        body: data
      })
    }),
    confirmForgotPassword: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/confirm-forgot-password`,
        method: "POST",
        body: data
      })
    }),
    getZones: builder.query({
      query: () => ({
        url: `${mdmsUrl}/administrative_hierarchy/zones`
      })
    }),
    getCircles: builder.query({
      query: (params) => ({
        url: `${mdmsUrl}/administrative_hierarchy/circles`,
        params: params
      })
    }),
    getDivisions: builder.query({
      query: (params) => ({
        url: `${mdmsUrl}/administrative_hierarchy/divisions`,
        params: params
      })
    }),
    getSubDivisions: builder.query({
      query: (params) => ({
        url: `${mdmsUrl}/administrative_hierarchy/sub-divisions`,
        params: params
      })
    })
  })
});

export const {
  useVerifyMFAMutation,
  useLazyAuthenticateUserQuery,
  useGetUsersQuery,
  useGetRolesQuery,
  useUpdateUserRoleMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
  useGetModulesQuery,
  useLazyGetModulesQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
  useConfirmMFASetupMutation,
  useForceChangePasswordMutation,
  useCreateUserMutation,
  useLazyResendUserInvitationQuery,
  useLazyGetUserInfoQuery,
  useGetUserInfoQuery,
  useChangePasswordMutation,
  useUpdateUserDetailsMutation,
  useForgotPasswordMutation,
  useConfirmForgotPasswordMutation,
  useGetZonesQuery,
  useGetCirclesQuery,
  useGetDivisionsQuery,
  useGetSubDivisionsQuery
} = cognitoUserAccessPanelApi;
