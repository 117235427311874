import { prepareHeaders } from "@/hooks/Headers";
import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const authUrl = "https://auth-service.sangai.polarisgrids.com";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authUrl,
    prepareHeaders: prepareHeaders
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    signOutUser: builder.query({
      query: () => ({
        url: `auth/users/sign-out`
      })
    }),
    getNewAccessToken: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/new-access-token`,
        method: "POST",
        body: data
      })
    }),
    signInUser: builder.mutation({
      query: (data) => ({
        url: `${authUrl}/auth/users/sign-in`,
        method: "POST",
        body: {
          ...data
        }
      })
    })
  })
});

export const {
  useLazySignOutUserQuery,
  useGetNewAccessTokenMutation,
  useSignInUserMutation
} = authApi;
