import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("accessToken") || false
};
const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isAuthenticated = false;
      localStorage.clear();
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    }
  }
});

export const { logOut, setIsAuthenticated } = authSlice.actions;
export default authSlice;
