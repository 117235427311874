import useAuth from "@/hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const isAuthenticated = useAuth(); // ✅ Call inside the component

  if (isAuthenticated === null || isAuthenticated === undefined) {
    return null; // Handle initial loading state if needed
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to="/"
      replace
    />
  );
};

export default PrivateRoute;
