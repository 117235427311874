import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../../hooks/Headers";
import { jwtDecode } from "jwt-decode";
import { authApi } from "../cognito-user-access-panel/auth";
import { logOut, setIsAuthenticated } from "@/app/redux/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: prepareHeaders
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const { status, originalStatus } = result.error;

    if (
      (status === 403 ||
        originalStatus === 403 ||
        status === 401 ||
        originalStatus === 401) &&
      localStorage.getItem("accessToken")
    ) {
      const refreshToken = localStorage.getItem("refreshToken");
      const token = localStorage.getItem("accessToken");

      if (!token || token === "undefined") {
        api.dispatch(logOut());
        return result;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const isExpired = decodedToken.exp < currentTime;

        if (isExpired) {
          const { data, error } = await api
            .dispatch(
              authApi.endpoints.signInUser.initiate({
                refreshToken,
                authFlow: "REFRESH_TOKEN_AUTH"
              })
            )
            .unwrap();
          if (error) {
            api.dispatch(logOut());
          } else if (data) {
            const { accessToken, idToken } = data;
            if (accessToken && idToken) {
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("idToken", idToken);
              api.dispatch(setIsAuthenticated(true));

              // Retry the original request with updated tokens
              result = await baseQuery(args, api, extraOptions);
            } else {
              api.dispatch(logOut());
            }
          }
        } else {
          api.dispatch(setIsAuthenticated(true));
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        api.dispatch(logOut());
      }
    } else if (
      status === 403 ||
      originalStatus === 403 ||
      status === 401 ||
      originalStatus === 401
    ) {
      api.dispatch(authApi.endpoints.signOutUser.initiate());
      localStorage.clear();
    }
  }

  return result;
};
