import { combineReducers } from "@reduxjs/toolkit";
import { commandHistoryApi } from "../api/hes/command-historySlice";
import { dropdownsApi } from "../api/hes/drop-downSlice";
import {
  utilityMDASAssetListReducer,
  utilityMDASDlmsCommandReducer
} from "../app/redux/commandExecutionSlice";
import { currentSelectedModuleReducer } from "./redux/previousSelectedModuleSlice";
import { MDMSHierarchyProgressReducer } from "./redux/mdmsHeirarchySlice";
import { pushDataApi } from "../api/hes/push-dataSlice";
import { meterConfigurationApi } from "../api/hes/meter-configurationSlice";
import layoutReducer from "./redux/layoutSlice";
import { energyConsumptionApi } from "../api/mdms/energy-consumptionSlice";
import { operationalStatsApi } from "../api/mdms/operational-statisticsSlice";
import { loadsApi } from "../api/mdms/loadSlice";
import { userConsumptionApi } from "../api/mdms/userConsumptionSlice";
import { energyConsumptionApiV2 } from "../api/mdms/energy-consumptionSliceV2";
import { slaReportsApi } from "@/api/sla-reports";
import { HealthPortalApi } from "@/api/coliving/health-portalSlice";
import { tariffDataApi } from "@/api/prepaid-config/tariffSlice";
import { factoryPush } from "@/api/factory-push";
import { holidaysApi } from "@/api/prepaid-config/holidaysSlice";
import { featuresAccessPanelApi } from "@/api/prepaid-config/features-access-panelSlice";
import { arrearConfigApi } from "@/api/prepaid-config/arrearConfigSlice";
import { prepaidDashboardSlice } from "@/api/prepaid-dashboard";
import { supersetApi } from "@/api/superset";
import { dtFeederMonitorSlice } from "@/api/dt-feeder-monitoring";
import { billingDashboardSlice } from "@/api/billing-dashboard";
import { timeDriftAPI } from "@/api/hes/timeDrift";
import { cognitoUserAccessPanelApi } from "@/api/cognito-user-access-panel";
import routeSlice from "./redux/routesSlice";
import { mdmsCommandExecutionApi } from "@/api/mdms/command-executionSlice";
import { authApi } from "@/api/cognito-user-access-panel/auth";
import { NFMSAPI } from "@/api/nfms";
import authSlice from "./redux/authSlice";

const rootReducer = combineReducers({
  [commandHistoryApi.reducerPath]: commandHistoryApi.reducer,
  [pushDataApi.reducerPath]: pushDataApi.reducer,
  [meterConfigurationApi.reducerPath]: meterConfigurationApi.reducer,
  [energyConsumptionApi.reducerPath]: energyConsumptionApi.reducer,
  [operationalStatsApi.reducerPath]: operationalStatsApi.reducer,
  [loadsApi.reducerPath]: loadsApi.reducer,
  [userConsumptionApi.reducerPath]: userConsumptionApi.reducer,
  [energyConsumptionApiV2.reducerPath]: energyConsumptionApiV2.reducer,
  [slaReportsApi.reducerPath]: slaReportsApi.reducer,
  [HealthPortalApi.reducerPath]: HealthPortalApi.reducer,
  [tariffDataApi.reducerPath]: tariffDataApi.reducer,
  [factoryPush.reducerPath]: factoryPush.reducer,
  [cognitoUserAccessPanelApi.reducerPath]: cognitoUserAccessPanelApi.reducer,
  [holidaysApi.reducerPath]: holidaysApi.reducer,
  [featuresAccessPanelApi.reducerPath]: featuresAccessPanelApi.reducer,
  [arrearConfigApi.reducerPath]: arrearConfigApi.reducer,
  [NFMSAPI.reducerPath]: NFMSAPI.reducer,
  layout: layoutReducer,
  [timeDriftAPI.reducerPath]: timeDriftAPI.reducer,
  [dropdownsApi.reducerPath]: dropdownsApi.reducer,
  [prepaidDashboardSlice.reducerPath]: prepaidDashboardSlice.reducer,
  [supersetApi.reducerPath]: supersetApi.reducer,
  [dtFeederMonitorSlice.reducerPath]: dtFeederMonitorSlice.reducer,
  [billingDashboardSlice.reducerPath]: billingDashboardSlice.reducer,
  [mdmsCommandExecutionApi.reducerPath]: mdmsCommandExecutionApi.reducer,
  [authApi.reducerPath]: [authApi.reducer],
  utilityMDASAssetList: utilityMDASAssetListReducer,
  utilityMDASDlmsCommand: utilityMDASDlmsCommandReducer,
  currentSelectedModule: currentSelectedModuleReducer,
  MDMSHierarchyProgress: MDMSHierarchyProgressReducer,
  routes: routeSlice.reducer,
  [authSlice.reducerPath]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer
});

export default rootReducer;
